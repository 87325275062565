@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}

html{
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
}

.page-wrapper{
  margin: 20px;
}

.text-blue{
  color : #0176C3 !important
}

.text-white{
  color : #fff
}

.text-black{
  color : #000
}

.navbar{
  display: flex;
  justify-content: space-between;
  margin: 50px;
}

.quick-links{
  z-index: 10;
}

.quick-links >a {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin: 0 40px; 
}

.button{
  background-color: #0176C3;
  color: #fff;
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
}

.white-button{
  background-color: #fff;
  color: #0176C3;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
}

.first-comp{
  display: flex;
  justify-content: row;
  background-image: url("./img/bg-comp-1.svg");
  background-size : contain;
  margin-left: 25px;
  margin-right: 25px;
  background-repeat: no-repeat;
  color: #fff;
}
.first-img > img{
  height: 1000px;
}

.first-comp-heading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: 100px;
  bottom: 225px;
  /* width: 60%; */
  text-align: justify;
  /* flex:0.5 */
}

.first-comp-heading > h1{
  font-size: 40px;
}
.first-comp-heading > p{
  width: 80%;
  font-size: 18px;
}

.services{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  /* flex:0.7; */
  margin : 0 200px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.left-service{
  flex : 0.3;
  background-color: #0176C3;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  z-index: -2;
 
}

.left-service>h3{
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}

.left-service>p{
  font-size: 14px;
}

.money-smartly{
  font-weight: 500;
}

.white-part{
  z-index: -1;
  position: absolute;
  bottom: -7px;
  left: 0px;
  border-bottom-left-radius: 20px;
}
.white-part > img{
  width: 100%;
}

.heights-img{
  width: 100%;
  z-index: 2;
}


.right-service{
  flex : 0.7;
  padding: 20px;
  margin: 20px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}


.select-service{
  font-size: 24ox;
  font-weight: 400;
}


.select-service > span{
  font-weight: 500;
}

.heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.service-info{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin: 20px;
  margin-top: 120px;
  
}

.service-info-left{
  flex : 0.5
}

.service-info-right{
  flex : 0.5;
}

.service-info  h2{
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 20px;
}

.services-ul{
  line-height: 60px;
}

.services-ul a{
  color: #000;
  font-size : 20px;

}

.df-jcsb{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.df-jcse{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.df-jcsa{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.info-card{
  border-radius: 10px;
  background-color: #0176C3;
  min-height: 200px;
  padding: 20px;
  line-height: 60px;
  min-width: 350px;
}

.center-container{
  display: flex;flex-direction: row;justify-content: center;
}

.get-expert{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0176C3;
  align-items: center;
  border-radius: 20px;
  margin: 25px;
  margin-top: 120px;
  padding: 50px 20px;
}

.get-expert button{
  background-color: white;
  color: #0176C3;
  border: 0;
  padding: 10px 30px;
}

.get-expert h3{
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}
.get-expert p{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
  opacity: 0.7;
  width: 70%;
}

.why-us,.mission{

  margin: 25px;
  margin-top: 120px;
  padding: 50px 20px;
}


.why-us h3{
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.why-us-content{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.vedic-image{
  background-color: #EDF2FE;
  border-radius: 50px;
  position: relative;
  padding: 20px;
  overflow: hidden;

}


.vedic-image img{
  width: 60%;
  align-items: center;
  position: relative;
  /* top: 105px; */
}

.vedic-white{
  background-color: #fff !important;
  z-index: 10 !important;
  height: 100px;
}

.tile{
  width: 350px;
  height: 175px;
  background-color: #0176C3;
  padding: 20px;
  margin: 20px 0px; 
  /* margin-left: 0px; */
  border-radius: 20px;
  position: relative;
}

.tile-sub-heading{
  font-size: 14px;
  margin-top: 10px;
}

.round-icon{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  right: 0;
}

.mission{
  background-image: url("./img/bg-comp-1.svg");
  background-repeat: no-repeat;
  background-size :contain;
  /* position: relative; */
}

.mission-heading{
  padding: 30px 50px;
}

.mission-heading h3 {
  font-size: 36px;
  font-weight: 500;
}


.mission-heading p {
  font-size: 20px;
  width: 60%;
  margin: 30px 0px;
  text-align: justify;
}

.mission-img{
  position: relative;
  left: 58vw;
  top: -300px;
}

.channel-partners{

  position: relative;
  /* top:-225px; */
  margin: 7rem 40px 
}
.channel-images{
  transform: scale(0.88);
}

.channel-images{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.channel-partners h3{
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center;
}

.contact{
  background-color: #0176C3;
  padding: 40px 120px;
  margin: -20px;
}

.contact-details{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cd-heading h2{
  font-size: 36px;
  font-weight: 500;
}

.cd-heading p {
  font-size: 20px;
}

.details-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
}

.contact-form{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 400px;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  left: -20px;
}

.contact-form input{
  border: 0;
  border-bottom: 1px solid #0176C3;
  padding: 10px;
  margin: 10px 0px;
}

.contact-form h3{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.contact-form p{
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}


.contact-form input ::placeholder{
  color: #0176C3 !important;
}

input[type="radio"] {
  display: none;
}


.contact-form input[type="radio"]:checked + label {
  background-color: #0176C3;
  color: #fff;
  text-align: center;
  border-radius: 10px;

}

.contact-form label {
  display: inline-block;
  width: 60px;
  padding: 10px;
  /* border: solid 2px #ccc; */
  transition: all 0.3s;
}

.newsletter{
  background-image: url("./img/newsletter.svg");
  height: 500px;
  padding: 40px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  position: relative;
  left: -20px;
}

.sc-card{
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 150px;
}

.sc-card h4{
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}

.social-img{
  position: absolute;
  top: -25px;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
}

.sc-card p{
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
}
.data{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.data p{
  width: 80%;
  margin: 20px 0px;
  /* font-size: ; */
}

.data h2{
  font-size: 30px;
  font-weight: 500;
  margin: 20px 0px;
}

.join-us input{
  border: 0;
  margin-left:1px ;
  width: 264px;
  height: 50px;
  padding-left:5px ;
}


.join-us input::placeholder{
  padding-left: 10px;
  color: #0176C3;
}

.input-holder{
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
  margin : 20px 0px;
  padding: 2px;
}

*:focus {
  outline: none;
}

.footer{
  margin-top: 100px;
  margin: 20px;
  padding: 20px;
  margin-right: 100px;
}

.vedic-logo{
  flex:0.7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vedic-logo p {
  /* width: 70%; */
  text-align: justify;
}

.navigations ul{
  line-height: 40px;
}

.copyrigh,.copyright-phn{
  font-size: 14px;
  opacity: 0.7;
}

.navigations-holder{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/* .footer{
  flex-direction: column;
} */

.navigations{
  margin-right: 150px;
}

.copyright-phn{
  display: none;
}


@media screen and (max-width: 600px) {
  .footer{
    flex-direction: column;
    margin-right: 20px;
  }

  .navigations{
    margin-right: 10px;
    margin-top: 25px;
  }

  .copyright{
    display: none;
  }
  .copyright-phn{
    display: block;
    text-align: center;
  }

  .vedic-logo{
    flex:0.9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .get-expert h3{
    font-size: 22px;
  }

  .get-expert p{
    font-size: 16px;
    width: 100%;
  }

  .why-us-content,.channel-images{
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .channel-images img{
    margin-left: -15px;
    padding: 20px;
    padding-right: 0px;
    width: 300px;
  }

  .df-jcsb{
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .first-comp,.navbar,.right-service,.service-info,.mission,.df-jcsa,.heading{
    display: flex;
    flex-direction: column;
  }
  .first-comp-heading{
    left: 0px;
  }
  .services{
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .first-comp{
    /* margin-top: 100px; */
    margin: 0 auto;
    background-size: cover;
    background-position: bottom 10px left 20px;
  }
  .first-img img{
    width: 300px;
  }
  .first-comp-heading{
    bottom: 0px;
    padding: 20px;
  }
  .first-comp-heading > p{
    width: 100%;
    font-size: 18px;
  }
  .mission-img{
    width: 400px;
  }
  .info-card{
    margin-left: -50px;
    min-width: 207px;
    width: 330px;
    font-size: 14px;
  }
  .right-service{
    margin: 10px;
    padding: 10px;
  }
  .input-holder{
    width:300px
  }
  .service-info{
    margin-top: 10px;
  }
  .still-confuse{
    display: none;
  }
  .cd-heading{
    padding: 20px;
  }

  .cd-heading h2{
    font-size: 28px;
  }
  .cd-heading p{
    font-size: 18px;
  }
  .contact-details{
    margin-bottom: 30px;
  }
  .contact-form{
    left: 0px;
    width: 320px;
  }
  .newsletter{
    height: auto;
  }
  .data p{
    width: 100%;
  }
  .join-us input{
    width: 164px;
  }
  .navbar{
    margin: 0px;
    margin-bottom: 50px;
  }
}

.text-fullform{
  text-align: left;
  margin-left: 40px;
}

.df-{
  display: flex;
  flex-direction: row;
}

.social-img img{
  width: 30px;
  height: 30px;
}
.logo{
  height: 70px;
}
.logo-footer{
  height: 50px;
}

.vedic-{
  height: 500px;
}
.about-page h1{
  font-weight: 700;
font-size: 64px;
line-height: 69px;
/* or 108% */

text-align: center;

color: #0176C3;
}
.about-page p{
  font-weight: 500;
font-size: 18px;
line-height: 32px;
/* or 164% */

text-align: center;

color: rgba(0, 0, 0, 0.7);
width: 85%;margin: 0 auto;margin-top: 5rem;
}

.landing-component{

  position: relative;
  width: 95%;margin: 0 auto;
  background: #0176C3;
border-radius: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;padding: 4rem;
  gap: 40px;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 13rem;
  margin-bottom: 12rem;padding-top: 7rem; 
}
.landing-component-right{position: absolute;right: 0;}
.landing-component-left h1{
  font-weight: 700;
font-size: 42px;
line-height: 60px;
/* or 125% */


color: #FFFFFF;
}
.landing-component-left p{
  font-weight: 500;
font-size: 18px;
line-height: 28px;
/* or 164% */


color: rgba(255, 255, 255,0.7);margin-top: 2rem;
}
.landing-component-left button{
  background: #FFFFFF;
  color: #0176C3;margin-top: 3rem;padding: 10px 28px;font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  
  
  color: #000000;
}
.landing-component-right img{
  position: absolute;top: 0px;right: 1%;
  /* width: 45%; */
}
@media (max-width:1500px) {
  .landing-component-right img{
     
    width: 45%;
  }
  .landing-component{
    padding-bottom: 10rem;
  }
}
@media (max-width:1200px) {
  .landing-component-right img{
     
    width: 55%;
  }
  .landing-component{
    padding-bottom: 8rem;
  }
}
@media (max-width:1000px){
  .landing-component{
    display: flex;
    flex-direction: column;
    padding-bottom: 0rem;

  }
  .landing-component-right{
    text-align: center;
    margin: 0 auto; 
  }
  .remove-br{
    display: none;
  }
  .landing-component-right{
    position: relative;

  }
  .landing-component-right >div{
    height: 400px !important;
    width: 500px !important;
  }
  .landing-component-right img{
     position: relative;
    width: 400px;margin: 0 auto;text-align: center;
  }
  .landing-component-left h1{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
  }
  .landing-component-left p{
    margin-top: 1.3rem;font-size: 16px;line-height: 24px;
  }
}
@media (max-width:600px){
  .landing-component-right >div,.about-page-lottie>div{
    height: 340px !important;
    width: 290px !important;
    
  }
  .get-expert{
    margin: 0;
    margin-top: 120px;
  }
  .landing-component{
    padding:3rem 1.4rem ;border-radius: 20px;padding-bottom: 0rem;
  }
}
.new-mission-vision-cont{
  width: 95%;margin: 0 auto;
  background: #0176C3;
border-radius: 39.6531px;padding: 6rem;padding-bottom: 0;
}
.new-mission-vision-cont h1{
  font-weight: 700;
font-size: 48.4427px;
line-height: 45px;
/* or 82% */

text-align: center;

color: #FFFFFF;
}
.mission-cta{
  display: flex;justify-content: center;
  text-align: center;margin-top: 2rem;
}
.mission-cont-img {text-align: center;margin-top: 2rem;}
.mission-cta a{
  font-weight: 600;
font-size: 20.1702px;
display: flex;align-items: center;
line-height: 36px;
text-align: center;

color: #FFFFFF;margin-left: 16px;
}
.new-mission-vision-cont  p{
  width: 80%;margin: 0 auto;
  font-weight: 400;
font-size: 22.624px;
line-height: 36px;margin-top: 2rem;
text-align: center;

color: rgba(255, 255, 255, 0.8);
}

.quick-phn-links{
  width: 83vw;
  /* margin-left: -35px; */
  margin-top: 20px;
  margin-bottom: -20px;
  display: none;
}
.quick-phn-links a{
  padding: 10px;
  color: #000;
}
@media (max-width:1100px) {
  .new-mission-vision-cont h1{
    font-size: 36.4427px;
    line-height: 42px;
  }
  .new-mission-vision-cont p{
    font-size: 18.624px;
    line-height: 30px;
    width: 90%;
  }
  .new-mission-vision-cont{
    overflow: hidden;
    padding: 5rem 1rem;padding-bottom: 0;
  }
  .mission-cont-img img{
    width: 100%;position: relative;top: 20px;transform: scale(1.2);
  }
  .white-button{
    font-size: 16px;
  }
  .mission-cta{
    display: flex;
    flex-direction: column;
  }
  .mission-cta a{
    display: none;
  }
  .quick-links{
    display: none;
  }
  .details-card p{
    font-size: 12px;
    display: flex;
  }
  .contact-top{
    /* margin-top: 50px; */
    width: fit-content;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
  }
  .about-page h1{
    font-size: 55px;
  }
  .quick-phn-links{
    display: block;
  }
}

.selected{
  background-color: #0176C3;
  color: #fff;
  
  border-radius: 5px;
}

.yes,.no{
  cursor: pointer;
  padding: 10px;
}